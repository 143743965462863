import React from "react"

const TopicList = () => {
  return (
    <div className="text-white">
      <h3
        className="uk-heading-divider pb-4"
      >
        Основные темы, с которыми ко мне обращаются:
      </h3>
      <div className="uk-text-meta">
        <ul>
          <li>Aлкогольная зависимость</li>
          <li>Зависимость от наркотиков</li>
          <li>Зависимость от никотина</li>
          <li>Сексуальная зависимость</li>
          <li>Созависимое поведение</li>
          <li>Любовная зависимость</li>
          <li>Игровая зависимость</li>
          <li>Созависимость в семейных отношениях</li>
          <li>Созависимость в отношениях с зависимыми членами семьи</li>
          <li>Кризисные жизненные ситуации</li>
          <li>Семейные кризисы</li>
          <li>Депрессия</li>
          <li>Тревожность</li>
          <li>Проблемы со сном</li>
          <li>Гиперопека</li>
          <li>Гиперконтроль в отношениях</li>
        </ul>
      </div>
    </div>
  )
}
export default TopicList
