import React from "react"
import checkIco from "../images/tick.svg"

const TextWithCheck = ({ text }) => {
  return (
    <div className="uk-card-default border-bottom border-primary p-5 text-center display-4">
      <div data-aos="zoom-in">
        <img src={checkIco} alt="check" style={{ width: "40px" }}></img>
        <span
          style={{ textTransform: "uppercase" }}
          className="pl-3 text-light"
        >
          {text}
        </span>
      </div>
    </div>
  )
}
export default TextWithCheck
